@mixin normalize-button {
  font-family: 'Public Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
  outline: none;
  border: hidden;
  &:focus {
    outline: none;
  }
}

@mixin medium-button-styles {
  height: var(--button-medium-height);
  padding: 0 var(--button-medium-padding-horizontal);
  border-radius: var(--button-medium-border-radius);
  font-size: var(--button-medium-font-size);
  font-weight: var(--button-medium-font-weight);
  line-height: var(--button-medium-line-height);
  &.icon-leading > * {
    margin-right: var(--button-medium-leading-icon-margin-right);
    & > i {
      font-size: var(--button-medium-icon-size) !important;
    }
  }
  &.icon-trailing > * {
    margin-left: var(--button-medium-leading-icon-margin-right);
    & > i {
      font-size: var(--button-medium-icon-size) !important;
    }
  }
}

.scu-button-group {
  display: flex;
  button:not(:first-child) {
    margin-left: 10px;
  }
}

.scu-button {
  @include normalize-button();
  display: flex;
  white-space: nowrap;
  align-items: center;
  text-transform: var(--button-text-transform);
  transition: transform 200ms;
  justify-content: center;
  &:active {
    transform: scale(0.97);
  }
  @include medium-button-styles();
  &:disabled,
  &[disabled] {
    color: var(--color-primary-2);
    &:active {
      transform: none;
    }
    & > i {
      opacity: 0.5;
    }
  }
}

.scu-button.primary {
  background: var(--color-primary);
  color: var(--color-white);
  border: var(--button-primary-border-default);
  &:hover,
  &:focus {
    background: var(--color-primary-dark);
  }
  &:disabled,
  &[disabled] {
    background: var(--color-primary-2);
  }
}

.scu-button.secondary {
  background: unset;
  color: var(--color-primary);
  border: var(--button-secondary-border-default);
  &:hover,
  &:focus {
    border: var(--button-secondary-border-focus);
    background: var(--color-primary-3);
  }

  &:disabled,
  &[disabled] {
    border-color: var(--color-neutral-4);
    color: var(--color-primary-2);
  }
}

.scu-button.link {
  background: unset;
  height: var(--button-link-height);
  color: var(--color-primary);
  border: none;
  border-radius: 0;
  border-bottom: 1px solid transparent;
  padding: 0;
  &:hover,
  &:focus {
    border-color: var(--color-primary);
  }

  &:disabled,
  &[disabled] {
    color: var(--color-primary-2);
  }
}

.scu-button.large {
  height: var(--button-large-height);
  padding: 0 var(--button-large-padding-horizontal);
  border-radius: var(--button-large-border-radius);
  font-size: var(--button-large-font-size);
  font-weight: var(--button-large-font-weight);
  line-height: var(--button-large-line-height);
  &.icon-leading > * {
    margin-right: var(--button-large-leading-icon-margin-right);
    & > i {
      font-size: var(--button-large-icon-size) !important;
    }
  }
  &.icon-trailing > * {
    margin-left: var(--button-large-leading-icon-margin-right);
    & > i {
      font-size: var(--button-large-icon-size) !important;
    }
  }
}

.scu-button.small {
  height: var(--button-small-height);
  padding: 0 var(--button-small-padding-horizontal);
  border-radius: var(--button-small-border-radius);
  font-size: var(--button-small-font-size);
  font-weight: var(--button-small-font-weight);
  line-height: var(--button-small-line-height);
  &.icon-leading > * {
    margin-right: var(--button-small-leading-icon-margin-right);
    & > i {
      font-size: var(--button-small-icon-size) !important;
    }
  }
  &.icon-trailing > * {
    margin-left: var(--button-small-leading-icon-margin-right);
    & > i {
      font-size: var(--button-small-icon-size) !important;
    }
  }
}

// Is default
.scu-button.medium {
  @include medium-button-styles();
}

button.block {
  width: 100%;
}
