// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import '~src/scu/src/styles/components/typography';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$placementstudentpanel-primary: mat.define-palette(mat.$indigo-palette);
$placementstudentpanel-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$placementstudentpanel-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$placementstudentpanel-theme: mat.define-light-theme(
  (
    color: (
      primary: $placementstudentpanel-primary,
      accent: $placementstudentpanel-accent,
      warn: $placementstudentpanel-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($placementstudentpanel-theme);

@function define-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal
) {
  @return (
    font-size: $font-size,
    line-height: $line-height,
    font-weight: $font-weight,
    font-family: $font-family,
    letter-spacing: $letter-spacing
  );
}
//$custom-typography: mat-typography-config(
//    $font-family: "'Open Sans', sans-serif",
//    $display-4: define-typography-level(8rem, 8rem, 300, $letter-spacing: -0.05em),
//    $display-3: define-typography-level(4rem, 4rem, 400, $letter-spacing: -0.02em),
//    //$display-2: define-typography-level(3.21rem, 3.42rem, 400, $letter-spacing: -0.005em),
//    $display-2: define-typography-level(3rem, 3.1rem, 400, $letter-spacing: -0.005em),
//    $display-1: define-typography-level(2.42rem, 2.85rem, 400),
//    $headline: define-typography-level(1.71rem, 2.28rem, 400),
//    $title: define-typography-level(1.62rem, 2.48rem, 500),
//    $subheading-2: define-typography-level(1.14rem, 2rem, 400),
//    $subheading-1: define-typography-level(1.07rem, 1.71rem, 400),
//    $body-2: define-typography-level(1rem, 1.71rem, 500),
//    $body-1: define-typography-level(1rem, 1.42rem, 400),
//    $caption: define-typography-level(0.85rem, 1.42rem, 400),
//    $button: define-typography-level(1rem, 1rem, 500),
//    $input: define-typography-level(inherit, 1.125, 400),
//);
//@include mat-core($custom-typography);

$app-typography: mat.define-typography-config(
  $font-family: "'Open Sans', sans-serif",
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(45px, 48px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 24px, 500),
  $subheading-2: mat.define-typography-level(16px, 20px, 500),
  $subheading-1: mat.define-typography-level(15px, 20px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(16px, 1.25, 400),
);

@include mat.core($app-typography);

$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

@include scu-base-typography();
