:root {
  --input-default-border-color: var(--color-grey-3);
  --input-default-border-radius: 4px;
  --input-default-padding-left: 12px;
  --input-default-padding-right: 12px;
  --input-default-padding-top: 8px;
  --input-default-padding-bottom: 8px;
  --input-default-font-size: 14px;
  --input-default-line-height: 20px;
  --input-default-color: var(--color-grey-1);
  --input-default-placeholder-color: var(--color-grey-2);
  --input-default-font-weight: 400;

  // focus
  --input-focus-border-color: var(--color-primary);

  // input label
  --input-label-margin-bottom: 4px;
  --input-label-font-size: 14px;
  --input-label-line-height: 20px;
  --input-label-color: var(--color-grey-2);

  // input helper text
  --input-helper-text-margin-top: 4px;
  --input-helper-text-font-size: 12px;
  --input-helper-text-line-height: 16px;
  --input-helper-text-color: var(--color-grey-2);

  // icon
  --input-icon-top: 8px;
  --input-start-icon-left: 12px;
  --input-start-icon-size: 16px;
  --input-start-icon-color: var(--color-grey-2);

  // icon end
  --input-end-icon-right: 12px;
  --input-end-icon-size: 16px;
  --input-end-icon-color: var(--color-grey-2);

  // text area
  --text-area-min-height: 120px;
}
