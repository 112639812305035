.scu-menu {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid var(--color-primary);
  &.position-bottom {
    margin-top: 8px;
    animation: slide-in-from-top 200ms;
  }
  &.position-left {
    margin-left: 8px;
    animation: slide-in-from-left 200ms;
  }
}

.scu-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--menu-item-default-padding);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-neutral-1);
  scu-icon {
    opacity: 0.7;
  }
  &.icon-leading {
    scu-icon {
      margin-right: 8px;
    }
  }
  &.icon-trailing {
    scu-icon {
      margin-left: 8px;
    }
  }
  &:hover {
    background: var(--color-neutral-6);
  }
  &.active {
    font-weight: 600;
    color: var(--color-primary);
  }
}

.scu-menu-header {
  display: flex;
  padding: var(--menu-item-default-padding);
  font-size: 14px;
  color: var(--color-neutral-2);
  scu-icon {
    margin-right: 8px;
  }
}

@keyframes slide-in-from-top {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-from-left {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
