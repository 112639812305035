:root {
  --button-text-transform: capitalize;
  // Primary
  --button-primary-border-default: 1px solid transparent;
  --button-primary-border-focus: 1px solid transparent;

  // Secondary
  --button-secondary-border-default: 1px solid var(--color-primary-2);
  --button-secondary-border-focus: 1px solid var(--color-primary);

  // Large
  --button-large-height: 44px;
  --button-large-padding-horizontal: 24px;
  --button-large-border-radius: 4px;
  --button-large-font-size: 14px;
  --button-large-font-weight: 600;
  --button-large-line-height: 20px;
  --button-large-leading-icon-margin-right: 8px;
  --button-large-icon-size: 16px;

  // Medium
  --button-medium-height: 36px;
  --button-medium-padding-horizontal: 20px;
  --button-medium-border-radius: 4px;
  --button-medium-font-size: 14px;
  --button-medium-font-weight: 600;
  --button-medium-line-height: 20px;
  --button-medium-leading-icon-margin-right: 8px;
  --button-medium-icon-size: 16px;

  // Small
  --button-small-height: 24px;
  --button-small-padding-horizontal: 8px;
  --button-small-border-radius: 4px;
  --button-small-font-size: 12px;
  --button-small-font-weight: 600;
  --button-small-line-height: 16px;
  --button-small-leading-icon-margin-right: 4px;
  --button-small-icon-size: 12px;

  --button-link-height: 20px;
}
