:root {
  // Primary
  --color-primary: hsla(223, 51%, 27%, 1);
  --color-primary-dark: hsla(223, 54%, 19%, 1);
  --color-primary-2: hsla(223, 19%, 64%, 1);
  --color-primary-3: hsla(228, 33%, 97%, 1);
  --color-primary-4: hsla(223, 46%, 15%, 1);
  --color-primary-5: hsla(223, 39%, 41%, 1);

  // Secondary
  --color-secondary: hsla(36, 60%, 49%, 1);
  --color-secondary-2: hsla(38, 49%, 75%, 1);
  --color-secondary-3: hsla(37, 50%, 90%, 1);

  // Functional Color system
  --color-success: hsla(84, 75%, 63%, 1);
  --color-success-2: hsla(84, 93%, 34%, 1);
  --color-success-3: hsla(84, 75%, 63%, 0.5);
  --color-success-4: hsla(84, 75%, 63%, 0.2);

  --color-error: hsl(0, 100%, 70%);
  --color-error-2: hsl(0, 92%, 38%);
  --color-error-3: hsla(0, 100%, 70%, 0.5);
  --color-error-4: hsla(0, 100%, 70%, 0.2);

  --color-info: hsl(202, 100%, 72%);
  --color-info-2: hsl(202, 100%, 37%);
  --color-info-3: hsla(202, 100%, 72%, 0.5);
  --color-info-4: hsla(202, 100%, 72%, 0.2);

  --color-warning: hsl(47, 100%, 69%);
  --color-warning-2: hsl(48, 93%, 38%);
  --color-warning-3: hsla(47, 100%, 69%, 0.5);
  --color-warning-4: hsla(47, 100%, 69%, 0.2);

  // Accents
  --color-accent-A: hsla(32, 100%, 75%, 1);
  --color-accent-A2: hsla(22, 66%, 31%, 1);
  --color-accent-A3: hsla(36, 100%, 75%, 0.5);
  --color-accent-A4: hsla(32, 100%, 75%, 0.2);

  --color-accent-B: hsla(255, 27%, 73%, 1);
  --color-accent-B2: hsla(254, 39%, 32%, 1);
  --color-accent-B3: hsla(254, 27%, 73%, 0.5);
  --color-accent-B4: hsla(254, 27%, 73%, 0.2);

  --color-accent-C: hsla(352, 70%, 82%, 1);
  --color-accent-C2: hsla(351, 52%, 34%, 1);
  --color-accent-C3: hsla(0, 100%, 70%, 0.5);
  --color-accent-C4: hsla(351, 70%, 82%, 0.2);

  --color-accent-D: hsla(169, 52%, 77%, 1);
  --color-accent-D2: hsla(182, 100%, 20%, 1);
  --color-accent-D3: hsla(169, 52%, 77%, 0.5);
  --color-accent-D4: hsla(169, 52%, 77%, 0.2);

  --color-accent-F: hsla(84, 33%, 67%, 1);
  --color-accent-F2: hsla(84, 70%, 23%, 1);
  --color-accent-F3: hsla(83, 55%, 64%, 0.5);
  --color-accent-F4: hsla(83, 55%, 64%, 0.2);

  --color-accent-G: hsla(202, 47%, 72%, 1);
  --color-accent-G2: hsla(202, 74%, 35%, 1);
  --color-accent-G3: hsla(204, 76%, 76%, 0.5);
  --color-accent-G4: hsla(204, 76%, 76%, 0.2);

  // accent dark
  --comp-dark-accent-A: hsla(274, 5%, 27%, 100%);

  --comp-dark-accent-A2: hsla(32, 100%, 75%, 1);

  // Orange
  --color-orange-2: hsla(36, 100%, 50%, 1);
  // @deprecated - use neutral colors-neutral
  // Greys
  --color-grey-1: hsla(0, 0%, 13%, 1);
  --color-grey-2: hsla(0, 0%, 43%, 1);
  --color-grey-3: hsla(0, 0%, 62%, 1);
  --color-grey-4: hsla(0, 0%, 81%, 1);
  --color-grey-5: hsla(0, 0%, 91%, 1);
  --color-grey-6: hsla(0, 0%, 95%, 1);

  --color-white: hsla(0, 0%, 100%, 1);
  --color-white-2: hsla(0, 0%, 60%, 1);
  --color-white-3: hsla(0, 0%, 40%, 1);
  --color-white-4: hsla(0, 0%, 20%, 1);
  --color-white-5: hsla(0, 0%, 10%, 1);
  --color-white-6: hsla(0, 0%, 5%, 1);

  // Neutrals
  --color-neutral-1: hsla(0, 0%, 5%, 1);
  --color-neutral-2: hsla(0, 0%, 5%, 0.7);
  --color-neutral-3: hsla(0, 0%, 5%, 0.4);
  --color-neutral-4: hsla(0, 0%, 5%, 0.2);
  --color-neutral-5: hsla(0, 0%, 5%, 0.1);
  --color-neutral-6: hsla(0, 0%, 5%, 0.05);
  --color-dark-neutral-2: hsla(0, 0%, 100%, 0.7);
  // Neutrals White
  --color-white-neutral-3-40: rgba(255, 255, 255, 0.4);

  --color-text-default: var(--color-grey-2);

  // FUNCTIONAL COLORS
  --color-functional-info-1: hsl(202, 100%, 37%);
  --color-functional-info-2: hsla(206, 99%, 31%, 1);
  --color-functional-info-3: hsla(199, 98%, 48%, 0.3);
  --color-functional-info-4: hsla(199, 98%, 48%, 0.1);
  // Components
  --comp-success-1: hsl(84, 74%, 93%);
  --comp-success-1-text: hsl(84, 92%, 30%);
  --comp-success-2: hsl(84, 92%, 30%);
  --comp-success-2-text: hsl(84, 74%, 93%);

  --comp-info-1: hsl(205, 76%, 95%);
  --comp-info-1-text: hsl(202, 74%, 35%);
  --comp-info-2: hsl(202, 74%, 35%);
  --comp-info-2-text: hsl(205, 76%, 95%);

  --comp-warning-1: hsl(46, 100%, 94%);
  --comp-warning-1-text: hsl(75, 3%, 26%);
  --comp-warning-2: hsl(48, 93%, 38%);
  --comp-warning-2-text: hsl(46, 100%, 94%);

  --comp-error-1: hsl(0, 100%, 94%);
  --comp-error-1-text: hsl(0, 92%, 38%);
  --comp-error-2: hsl(0, 92%, 38%);
  --comp-error-2-text: hsl(0, 100%, 94%);

  --comp-accent-A: hsl(32, 100%, 95%);
  --comp-accent-A-text: hsl(274, 5%, 27%);
  --comp-accent-A2: hsl(22, 66%, 31%);
  --comp-accent-A2-text: hsl(32, 100%, 95%);
  --comp-accent-A3: hsla(32, 100%, 75%, 0.5);
  --comp-accent-A4: hsla(32, 100%, 75%, 0.2);

  --comp-accent-B: hsl(249, 26%, 95%);
  --comp-accent-B-text: hsl(229, 24%, 28%);
  --comp-accent-B2: hsl(254, 39%, 32%);
  --comp-accent-B2-text: hsl(249, 26%, 95%);

  --comp-accent-C: hsl(352, 56%, 95%);
  --comp-accent-C-text: hsl(258, 17%, 27%);
  --comp-accent-C2: hsl(351, 52%, 34%);
  --comp-accent-C2-text: hsl(352, 56%, 95%);

  --comp-accent-D: hsl(170, 50%, 95%);
  --comp-accent-D-text: hsl(208, 27%, 28%);
  --comp-accent-D2: hsl(182, 100%, 20%);
  --comp-accent-D2-text: hsl(170, 50%, 95%);
}

@mixin dark-theme {
  --color-primary: hsla(228, 33%, 97%, 1);
  --color-primary-3: hsla(223, 51%, 27%, 1);
  --color-secondary: hsla(38, 49%, 75%, 1);
  --color-secondary-2: hsla(36, 49%, 51%, 1);
  --color-secondary-3: hsla(37, 50%, 90%, 1);
  --color-grey-1: hsla(0, 0%, 100%, 1);
  --color-grey-2: hsla(0, 0%, 100%, 0.6);
  --color-grey-5: rgb(28, 50, 105);
  --color-grey-6: hsla(223, 54%, 19%, 1);

  --color-primary-dark: hsla(0, 0%, 100%, 0.8);
  --color-white: hsla(223, 51%, 27%, 1);
  --color-text-default: hsla(0, 0%, 95%, 1);

  // Components
  --comp-success-1: hsl(158, 15%, 24%);
  --comp-success-1-text: hsl(84, 74%, 93%);
  --comp-success-2: hsl(84, 75%, 63%);
  --comp-success-2-text: hsl(158, 15%, 24%);

  --comp-info-1: hsl(213, 43%, 27%);
  --comp-info-1-text: hsl(205, 76%, 95%);
  --comp-info-2: hsl(202, 47%, 72%);
  --comp-info-2-text: hsla(213, 43%, 27%);

  --comp-warning-1: hsl(75, 3%, 26%);
  --comp-warning-1-text: hsl(46, 100%, 94%);
  --comp-warning-2: hsl(47, 100%, 69%);
  --comp-warning-2-text: hsl(75, 3%, 26%);

  --comp-error-1: hsl(305, 20%, 22%);
  --comp-error-1-text: hsl(0, 100%, 94%);
  --comp-error-2: hsl(0, 100%, 70%);
  --comp-error-2-text: hsl(305, 20%, 22%);

  --comp-accent-A: hsl(274, 5%, 27%);
  --comp-accent-A-text: hsla(32, 100%, 75%, 1);
  --comp-accent-A2: hsla(32, 100%, 75%, 1);
  --comp-accent-A2-text: hsl(274, 5%, 27%);
  --comp-accent-A3: hsla(32, 100%, 75%, 0.5);
  --comp-accent-A4: hsla(32, 100%, 75%, 0.2);

  --comp-accent-B: hsl(229, 24%, 28%);
  --comp-accent-B-text: hsl(249, 26%, 95%);
  --comp-accent-B2: hsl(255, 27%, 73%);
  --comp-accent-B2-text: hsl(229, 24%, 28%);

  --comp-accent-C: hsl(258, 17%, 27%);
  --comp-accent-C-text: hsl(352, 56%, 95%);
  --comp-accent-C2: hsl(351, 58%, 74%);
  --comp-accent-C2-text: hsl(258, 17%, 27%);

  --comp-accent-D: hsl(208, 27%, 28%);
  --comp-accent-D-text: hsl(170, 50%, 95%);
  --comp-accent-D2: hsl(169, 52%, 77%);
  --comp-accent-D2-text: hsl(208, 27%, 28%);
  // Neutrals
  --color-neutral-1: hsla(0, 0%, 5%, 1);
  --color-white-6: hsla(0, 0%, 100%, 1);
}

:root {
  // preferred by user's system
  // @media (prefers-color-scheme: dark) {
  //   @include dark-theme();
  // }
}

// based on a UI toggle
html[data-prefers-dark-theme='true'] {
  @include dark-theme();
}
