.bulleted-list {
    list-style: disc;
    color: hsla(223, 51%, 27%, 0.55);
    padding-left: 16px;
    &-item {
        padding: 4px;
        font-size: 12px;
        line-height: 16px;

    }
}
