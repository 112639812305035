@mixin use-public-sans-variable {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  @supports (font-variation-settings: "wght" 200) {
    font-family: "Public Sans", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  }
}

@font-face {
  font-family: "Public Sans";
  src: url("./PublicSans-VariableFont_wght.ttf");
  font-weight: 100 900;
  font-style: normal;
}
