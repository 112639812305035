.scu-bottom-sheet {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--color-white);
  border-radius: 8px 8px 0 0;
  overflow-y: scroll;

  &-slide-in {
    animation: custom-bottom-sheet-enter 0.3s ease;
  }

  &-slide-out {
    animation: custom-bottom-sheet-enter 0.3s ease reverse forwards;
  }
}

@keyframes custom-bottom-sheet-enter {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.scu-side-drawer {
  position: fixed !important;
  width: 400px;
  height: 100%;
  background: var(--color-white);
  right: 0;
  top: 0;
  left: unset;
  overflow-y: scroll;

  &-slide-in {
    animation: slide-from-right 0.2s ease;
  }

  &-slide-out {
    animation: slide-from-right 0.2s ease reverse forwards;
  }
}

.scu-side-drawer-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

  .scu-side-drawer-heading {
    font-weight: 600;
    font-size: 20px;
    color: #000;
  }
}

.scu-side-drawer-body {
  padding: 20px;
}

@keyframes slide-from-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
