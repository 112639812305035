.se-side-drawer-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;

  .se-side-drawer {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .se-side-drawer-backdrop {
    z-index: 1;
  }

  @media all and (min-width: 991px) {
    .se-side-drawer {
      width: 480px;
      right: 0;
      left: unset;
    }
  }
}

.se-side-drawer-heading {
  font-size: 18px;
  color: var(--color-white-4);
  margin: 0;
}

.se-side-drawer-body {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 24px 24px;

  &.scrollable {
    overflow-y: auto;
    height: calc(100% - 74px);
  }
}

.se-side-drawer-navigation-button {
  cursor: pointer;
  height: 24px;
  width: 24px;
  transition: transform 200ms;
  transform: translateX(0);

  &:hover {
    transform: translateX(-3px);
  }
}

.se-side-drawer {
  background: var(--color-white);
  animation: slide-from-right forwards 200ms;
}

.se-side-drawer-header {
  display: flex;
  align-items: center;
  padding: 20px;

  .se-side-drawer-navigation-button {
    margin-right: 8px;
  }

  .se-side-drawer-heading {
    margin: 0;
  }
}

.se-side-drawer-backdrop {
  animation: fade-in forwards 200ms;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(45px);
  }

  100% {
    transform: translateY(0);
  }
}
.no-animation {
  animation: none !important;
}
