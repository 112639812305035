// To be passed onto the `panelClass` property while mounting a modal/bottom-sheet
.mat-modal-container,
.mat-modal-container .mat-bottom-sheet-container {
  padding: 0 !important;
  border-radius: 12px 12px 0 0;
  max-height: unset;

  &.no-background {
    background: none;

    mat-dialog-container {
      background: none;
    }
  }

  mat-dialog-container {
    padding: 0;
  }
}
.mat-widget-container .mat-bottom-sheet-container {
  padding: 0px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 12px 12px 0 0;
  min-width: 100vw;
  max-height: calc(100vh - 120px);
  max-height: calc(100dvh - 40px);
}

.mat-modal-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.se-modal-backdrop {
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
}

.se-modal {
  width: auto;
  background: white;
  z-index: 2;
  border-radius: 4px;
  --modal-padding-default: 16px;
  --modal-border-default: 1px solid var(--color-grey-6);

  &.fixed {
    z-index: 101;
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
}

.se-modal-header {
  padding: var(--modal-padding-default);
  border-bottom: var(--modal-border-default);
}

.se-modal-heading {
  margin: 0;
  font-size: 24px;
  line-height: 120%;
}

.se-modal-body {
  display: flex;
  padding: var(--modal-padding-default);
  flex-direction: column;
  align-items: stretch;

  &.scrollable {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

.se-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--modal-padding-default);

  & > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.container-to-right {
  position: fixed !important;
  right: 0;
  padding: 0 !important;

  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 0;
  }
}

.bottom-sheet-fill {
  height: -moz-available;
  height: -webkit-fit-available;
  height: fill-available;
  height: fill;
}

//set video player width
.video-height-196 {
  height: 196px;
}
// global plyr color scheme
.player {
  --plyr-color-main: rgba(12, 12, 12, 0.4);
  --plyr-range-fill-background: #e53935;
  --plyr-range-thumb-height: 8px;
  --plyr-range-thumb-background: #e53935;
  --plyr-range-track-height: 2px;
  color: #fff;
}
//side player controls hack
.side-player {
  .plyr__controls {
    align-items: flex-start !important;
    flex-direction: column !important;
    text-align: left !important;
    background: linear-gradient(
      179.41deg,
      rgba(0, 0, 0, 0.25) -1.84%,
      rgba(0, 0, 0, 0.54) 74.61%,
      rgba(0, 0, 0, 0.87) 101.37%
    ) !important;
    height: 100%;
  }
  .plyr__controls div {
    width: 100%;
  }

  .scu__plyr__controls {
    display: flex;
    align-items: center;
  }
  .scu__plyr__overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.1px;

    color: #ffffff;

    button {
      touch-action: manipulation;
      background: rgba(12, 12, 12, 0.4);
      border: 0;
      border-radius: 100%;
      padding: 14px 14px 14px 16px;
      width: 48px !important;
      height: 48px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  // plyr__control--overlaid
  .plyr--video .plyr__controls {
    padding: 0 !important;
  }
  .scu-plyr-timer {
    padding-left: 8px;
  }
}

.full-height {
  height: 100%;
}
