$breakpoints: (
  'extra-small': 340px,
  'small': 767px,
  'medium': 992px,
  'large': 1200px,
) !default;

@mixin respond-to($breakpoint) {
  //min-width
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin adapt-to($breakpoint) {
  //max-width
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin custom-form-field() {
  ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: var(--bg-light-blue);
    border-radius: 4px;
  }

  ::ng-deep.mat-form-field-underline {
    opacity: 0;
  }

  //::ng-deep.mat-form-field-infix {
  //}

  ::ng-deep.mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 0.75em;
  }

  ::ng-deep.mat-form-field-suffix {
    margin: auto;
    color: var(--primary-color);
  }

  ::ng-deep.mat-form-field-prefix {
    margin: auto;
  }
}

@mixin cgpa-select() {
  ::ng-deep .mat-form-field {
    width: 60px;
    height: 28px;
    margin-top: -10px;
  }

  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
    background: transparent;
  }

  ::ng-deep .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0%);
  }
}
