.scu-tab {
  cursor: pointer;
  color: var(--color-primary-dark);
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid var(--color-neutral-5);
  padding: 10px 12px;
  flex-shrink: 0;
  line-height: 150%;
  transition: font-weight 200ms;

  &.active {
    color: var(--color-primary);
    border-bottom-color: var(--color-primary);
    font-weight: 600;
  }

  &.disabled {
    color: var(--color-grey-3);
    border-bottom-color: var(--color-neutral-5);
    pointer-events: none;
  }

  &.outline {
    padding: 8px 35px;
    border-radius: 30px;
    border: 1px solid var(--color-neutral-5);
    margin-left: 12px;

    &:last-child {
      margin-right: 12px;
    }

    &.active {
      background-color: var(--color-primary);
      color: var(--color-white);
      font-weight: 600;
    }

    &.disabled {
      color: var(--color-grey-3);
      pointer-events: none;
    }
  }

  &.isDarkBg {
    border-color: unset;
    color: var(--color-dark-neutral-2);

    &.active {
      background: var(--color-white);
      color: var(--color-primary);
    }
  }
}

.scu-tab-container {
  overflow-x: auto;
  display: flex;
  list-style: none;
  padding: 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
