// @deprecated
// Use colors.less instead
:root {
    --primary-color: #22366a;
    --primary-light: #4E5E88;
    --secondary-color: #c08e42;
    --content-color: #818ca9;
    --primary-color-rgb: 34, 54, 106;
    --secondary-color-rgb: 192, 142, 66;
    --texttransform-case: lowercase;
    --infotest-color: #7f4c0a;
    --infotest-lightcolor: #d4b011;
    --infotest-lightcolor-rgb: 212, 176, 17;

    --text-grey: #8891ac;
    --text-grey-light: #4F4F4F;
    --text-grey90: #909090;
    --text-grey-alt: #606060;
    --text-grey-dark: #404040;
    --text-dark: #2d2d2d;
    --text-orange: #c78f2b;
    --text-grey-82: #828282;
    --text-light-blue: #01579B;
    --grey-icon: #c4c4c4;
    --bg-light: #fbfbfb;
    --bg-light-border: #F3F3F3;
    --border-light-blue: #d6e4ec;
    --striking-blue: #6591f5;
    --blazing-blue: #0d93fe;
    --depressing-yellow: rgba(248, 236, 219, 0.5);
    --depressing-yellow-alt: #FFF8EC;
    --depressing-yellow-light: #FFFBF4 ;
    --plant-green: #1D944F;
    --success-green: #44CA00;
    --disabled-grey: #BEC2CC;
    --bg-green: #EFF6F2;
    --bg-grey: #F8F9FB;
    --bg-grey-darker: #F0F0F0;
    --danger-red: #F33429;
    --delete-red: #FB3C44;
    --logout-red: #FF5353;
    --bg-light-blue: #F5F6FA;
    --bg-verified: #F3FFF8;
    --text-verified: #27AE60;
    --grey-1: hsla(0, 0%, 94%, 1);
    --grey-3: #333333;
    --bg-dark-grey: #EEEEEE;
    --bg-grey-F5: #F5F5F5;

    --aside-modal-width: 460px;
}
