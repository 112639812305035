.side-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  overflow: auto;
  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    overflow: auto;
    background: rgba(0, 0, 0, 0.6);
  }
  &-content {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    height: 100vh;
    overflow: auto;
    padding: 40px 30px;
  }
}

@media all and (max-width: 992px) {
  //as full screen popup
  .side-drawer {
    &.full-screen-mob {
      .side-drawer-content {
        width: 100%;
        padding: 0 16px;
      }
    }
  }

  //as activity sheet
  .side-drawer {
    &.activity-sheet-mob {
      .side-drawer-content {
        border-radius: 4px 4px 0 0;
        width: 100%;
        height: auto;
        bottom: 0;
        top: auto;
      }
    }
  }
}
