@import 'typography-utils';

// Taken from material typography, altered for scu

// Represents a typography level from the Sunstone design spec.
@function scu-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal
) {
  @return (
    font-size: $font-size,
    line-height: $line-height,
    font-weight: $font-weight,
    font-family: $font-family,
    letter-spacing: $letter-spacing
  );
}

// Represents a collection of typography levels.
@function scu-typography-config(
  $font-family: 'Public Sans, sans-serif',
  $display-large: scu-typography-level(48px, 58px, 600),
  $display-medium: scu-typography-level(40px, 48px, 610),
  $heading-large: scu-typography-level(32px, 38px, 610),
  $heading-medium: scu-typography-level(24px, 28px, 610),
  $title-large: scu-typography-level(20px, 24px, 610),
  $title-medium: scu-typography-level(16px, 20px, 610),
  $body-1: scu-typography-level(16px, 22px, 410),
  $body-2: scu-typography-level(14px, 20px, 410),
  $caption-1: scu-typography-level(12px, 16px, 410),
  //$caption-2:       scu-typography-level(10px, 14px, 400), // original
  $caption-2: scu-typography-level(10.5px, 14px, 410),
  // altered to suit
) {
  // Declare an initial map with all of the levels.
  $config: (
    display-large: $display-large,
    display-medium: $display-medium,
    heading-large: $heading-large,
    heading-medium: $heading-medium,
    title-large: $title-large,
    title-medium: $title-medium,
    body-1: $body-1,
    body-2: $body-2,
    caption-1: $caption-1,
    caption-2: $caption-2,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      $new-level: map-merge(
        $level,
        (
          font-family: $font-family,
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

// Adds the base typography styles, based on a config.
@mixin scu-base-typography($config: scu-typography-config(), $selector: '.scu-typography') {
  .scu-h1,
  #{$selector} h1 {
    @include mat-typography-level-to-styles($config, display-large);
    margin: 0;
  }

  .scu-h2,
  #{$selector} h2 {
    @include mat-typography-level-to-styles($config, display-medium);
    margin: 0;
  }

  .scu-h3,
  #{$selector} h3 {
    @include mat-typography-level-to-styles($config, heading-large);
    margin: 0;
  }

  .scu-h4,
  #{$selector} h4 {
    @include mat-typography-level-to-styles($config, heading-medium);
    margin: 0;
  }

  .scu-h5,
  #{$selector} h5 {
    @include mat-typography-level-to-styles($config, title-large);
    margin: 0;
  }

  .scu-h6,
  #{$selector} h6 {
    @include mat-typography-level-to-styles($config, title-medium);
    margin: 0;
  }

  #{$selector} .scu-body-1 {
    @include mat-typography-level-to-styles($config, body-1);
    margin: 0;
  }

  #{$selector} .scu-body-2 {
    @include mat-typography-level-to-styles($config, body-2);
    margin: 0;
  }

  #{$selector} .scu-caption-1 {
    @include mat-typography-level-to-styles($config, caption-1);
    margin: 0;
  }

  #{$selector} .scu-caption-2 {
    @include mat-typography-level-to-styles($config, caption-2);
    margin: 0;
  }

  .scu-regular,
  regular #{$selector} {
    font-weight: 400 !important;
  }

  .scu-semi-bold,
  semi-bold #{$selector} {
    font-weight: 600 !important;
  }

  .scu-bold,
  bold #{$selector} {
    font-weight: 700 !important;
  }

  #{$selector} .color-neutral-3 {
    color: var(--color-neutral-3);
  }
  #{$selector} .color-neutral-2 {
    color: var(--color-neutral-2);
  }
  #{$selector} .color-neutral-1 {
    color: var(--color-neutral-1);
  }
}
