@import './styles/list.scss';
@import './styles/modal.scss';
@import './styles/layout.scss';
@import './styles/side-drawer.scss';
@import './styles/side-drawer-v2.scss';
@import 'src/scu/src/styles.scss';
@import '~src/assets/css/mixins';
// $primary-color: #C08E42;
// $secondary-color: #22366A;

body {
  --bottom-navigation-height: 0;
}

*:not(i) {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Public Sans', sans-serif !important;
  // font-family: 'Nunito', sans-serif !important;
  box-sizing: border-box;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

.underline {
  text-decoration: underline !important;
}

textarea {
  resize: none !important;
}

.main_container {
  background-color: #f5f6fa;
  padding: 20px calc(25vw + 20px) 30px calc(20vw + 20px);
  //   padding-top: 20px;
  //   padding-left: calc(20vw + 20px);
  //   padding-right: calc(30vw + 20px);
  //   padding-bottom: 75px;
  min-height: 100vh;
}

.ongoing_apps {
  // .mat-expansion-indicator{
  //   &::after{
  //     color: #4a90e2;
  //   }
  // }
  .mat-expansion-panel-body {
    padding: 0 25px;
  }
}

.mobile_no_box {
  .mat-form-field-appearance-legacy {
    .mat-form-field-underline {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.content_main {
  .fullprofile_section,
  .profile_section {
    .mat-button-toggle-group-appearance-standard {
      .mat-button-toggle + .mat-button-toggle {
        border-left: 1px solid #757575;
      }

      .mat-button-toggle-label-content {
        line-height: 48px !important;
      }
    }

    .mat-button-toggle-checked {
      background-color: #3a6cc7;
      color: #fff !important;
    }

    .mat-form-field-appearance-outline {
      &.mat-form-field-invalid {
        .mat-form-field-outline-thick {
          color: var(--secondary-color) !important;
        }
      }
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: #4676cb;
      }
    }

    .mat-select-value {
      // font-size: 17px;
      font-size: 16px;
    }

    .mat-form-field {
      .mat-form-field-label {
        mat-label {
          color: #4676cb;
          // font-size: 17px;
          // font-weight: 600;
          font-size: 16px;
          font-weight: 500;
        }
      }

      &.mat-form-field-invalid {
        .mat-form-field-label {
          mat-label {
            color: var(--secondary-color);
          }
        }
      }
    }

    // .mat-form-field-appearance-outline{
    //     &.mat-form-field-can-float{
    //         &.mat-form-field-should-float{
    //             .mat-form-field-label{
    //                 transform: translateY(-29.5px) scale(0.75);
    //             }
    //         }
    //     }
    // }
    // .mat-form-field-label-wrapper{
    //     top: -6px;
    // }
  }
}

.schedule_popup {
  .mat-form-field-appearance-outline {
    &.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: var(--secondary-color) !important;
      }
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: #4676cb;
    }
  }

  .mat-form-field {
    &.mat-form-field-invalid {
      .mat-form-field-label {
        mat-label {
          color: var(--secondary-color);
        }
      }
    }
  }
}

mat-accordion {
  mat-expansion-panel {
    .mat-expansion-panel-header-title {
      display: block;
    }

    .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
      background: none !important;
    }

    .mat-expansion-indicator {
      &::after {
        color: var(--primary-color);
        vertical-align: top;
      }

      &::after {
        color: var(--primary-color);
        vertical-align: top;
      }
    }
  }
}

.info_edit_box {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: #4a4a4a;
    }
  }

  // .mat-select-value {
  //     font-size: 16px;
  // }
}

.opportunity_cont {
  .company_info {
    .body {
      p {
        ul {
          list-style-type: disc;
          padding-left: 20px;
        }

        ol {
          padding-left: 20px;
        }

        h1,
        h2 {
          margin-bottom: 3px;
        }
      }
    }
  }

  .additional_details {
    ul {
      li {
        span {
          ul,
          ol {
            padding-left: 15px;
          }

          ul {
            list-style-type: disc;
          }
        }
      }
    }
  }
}

.mat-radio-label {
  white-space: break-spaces !important;
}

.main_cont {
  .main_content {
    .mat-select-value {
      color: #656565;
      font-size: 16px;
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: #4a4a4a !important;
      }
    }

    .about_cmp {
      .cmp_box {
        .info_box {
          .desc {
            p {
              margin-bottom: 0;
            }

            h1,
            h2 {
              margin-bottom: 3px;
            }

            ul {
              list-style-type: disc;
            }

            ul,
            ol {
              padding-left: 20px;
            }
          }
        }
      }
    }

    .submit_btn {
      width: 290px;
      height: 53px;
      margin: 80px auto 0;

      button,
      a {
        width: 100%;
        text-align: center;
        background: var(--primary-color);
        overflow: hidden;
        line-height: 53px;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        display: block;
        border: none;
        border-radius: 0;
        line-height: 45px;
        text-align: center;

        &[disabled] {
          background-color: #d4d3d3;
        }
      }
    }

    .detail_form {
      form {
        .mat-radio-label {
          font-weight: 400;
          color: var(--content-color);
        }

        .radio_que {
          .mat-radio-label {
            font-weight: 400;
            color: var(--content-color);
          }
        }
      }

      .work_exp_box {
        .mat-radio-label {
          font-weight: 400;
          color: var(--content-color);
        }

        .agreement {
          .mat-radio-label-content {
            padding-left: 14px;
            line-height: normal;
          }

          .mat-radio-label {
            color: #4a4a4a;
            font-size: 18px;
            font-weight: 600;
            align-items: initial;
          }

          .mat-radio-outer-circle,
          .mat-radio-inner-circle {
            border-radius: 0;
            height: 16px;
            width: 16px;
          }

          .mat-radio-outer-circle {
            border: 1px solid;
          }

          .mat-radio-container {
            margin-top: 2px;
          }

          .mat-radio-checked {
            .mat-radio-inner-circle {
              transform: scale(0.6);
            }
          }

          .mat-radio-button {
            &.mat-primary {
              .mat-radio-inner-circle {
                background-color: #4481ec;
              }

              &.mat-radio-checked {
                .mat-radio-outer-circle {
                  border-color: #4481ec;
                }
              }
            }
          }
        }

        // .mat-checkbox-layout{
        //     font-size: 16px;
        //     font-weight: normal;
        // }
      }
    }

    .last_agreement {
      padding-right: 10px;

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        display: none;
      }

      /* Handle */

      ::-webkit-scrollbar-thumb {
        width: 9px;
        background-color: #d8d8d8;
        // margin-right: 10px;
      }

      /* Handle on hover */

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.expform_box {
  .mat-radio-label {
    color: #313132;
    font-size: 14px;
    font-weight: normal;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: var(--content-color);
    }
  }
}

.none {
  display: none !important;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

.global_search_field {
  height: 42px;
  border-bottom: 1px solid #c5c5c5 !important;
  padding: 10px !important;

  &.with_srch {
    padding-left: 45px !important;
  }
}

.search_field_wrap {
  position: relative;
  // .global_search_field{
  //     height: 42px;
  //     border-bottom: 1px solid #c5c5c5 !important;
  //     padding: 10px !important;
  // }
  mat-icon {
    width: 20px;
    height: 20px;
    color: var(--content-color);
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;

    &.srch {
      left: 15px;
      color: #000000;
    }
  }
}

.hide_bgscroll {
  height: 100vh;
  overflow: hidden;
}

.add_experience {
  .mat-radio-button {
    margin-right: 54px;
  }

  .mat-radio-label {
    font-weight: normal;
    color: #313132;
  }

  mat-form-field {
    max-width: 358px;
    display: block;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: #4a4a4a;
    }
  }
}

.info_box_lead {
  position: absolute;
  right: -20px;
  top: 0px;
  transform: translate(0, -50%);
  z-index: 99;
  background: #fff;

  .tooltip_box {
    position: absolute;
    display: none;
    width: 200px;
    box-shadow: -10px 9px 51px 0 rgba(0, 0, 0, 0.23);
    background: #fff;
    padding: 14px 20px;
    bottom: 0;

    p {
      font-size: 14px;
      color: var(--content-color);
      line-height: normal;
      margin: 0;
    }

    &.right {
      right: 0;
      left: auto;
    }

    &.left {
      right: auto;
      left: 0;
    }

    &.center {
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
  }

  i {
    border-radius: 100%;
    border: 1px solid #666;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: var(--content-color);
    line-height: normal;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    cursor: pointer;
  }

  &:hover {
    .tooltip_box {
      display: block;
    }
  }
}

.ck {
  &.ck-editor__main {
    > .ck-editor__editable {
      padding-left: 30px;

      ul {
        list-style-type: disc;
      }
    }
  }
}

.ck-editor__editable_inline {
  height: 158px;
}

.form_wrap {
  .secondtab {
    .mat-checkbox-layout {
      white-space: normal;
    }
  }
}

.aggrement_consent {
  .mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
      background: #22366a;
    }
  }
}

.info_cont {
  .info_body {
    .info_body_inner {
      .acad_basic {
        .acad_separate_box {
          .body {
            .cgpa_percent_box {
              mat-radio-group {
                .mat-radio-label {
                  .mat-radio-container {
                    .mat-radio-outer-circle {
                      height: 18px;
                      width: 18px;
                    }

                    .mat-radio-inner-circle {
                      height: 18px;
                      width: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tab-area {
  .mat-tab-group {
    .mat-tab-header {
      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border: thin solid #ccc;
            padding: 15px 0px;

            .mat-tab-label {
              height: 25px;
              padding: 0;
              font-weight: bold;
              font-size: 11px;
              color: #263567;
              border-right: thin solid #ccc;
              width: 50%;

              & + .mat-tab-label {
                border-right: none;
              }
            }
          }
        }
      }
    }

    .mat-tab-body-wrapper {
      padding: 2px 0px;
    }
  }
}

.admission_main {
  // .cdk-overlay-pane{
  //     margin: 100px 0 0 0;
  // }
  .info_box {
    .mat-button-toggle-checked {
      background-color: var(--primary-color);
      color: #fff;
    }

    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        width: 64px;
        line-height: 25px;
        border: none;
        border-radius: 0px;
        // border-left: #000 solid thin;
        // border-bottom: #000 solid thin;
        border: var(--primary-color) solid thin;
        // border-right: none;
      }
    }

    .mat-button-toggle-group-appearance-standard {
      border: 0px;
      border-radius: 0px 4px 0px 0px;
      font-size: 14px;

      .mat-button-toggle + .mat-button-toggle {
        border-left: none;

        .mat-button-toggle-label-content {
          border-radius: 0px 4px 0 0;
        }
      }
    }

    .mat-checkbox-label {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }

    .mat-checkbox-checked {
      &.mat-accent {
        .mat-checkbox-background {
          background-color: var(--secondary-color);
        }
      }
    }

    .form_box {
      .mat-form-field-appearance-outline {
        .mat-form-field-outline-end {
          border-radius: 0 4px 4px 0;
        }

        .mat-form-field-outline-start {
          border-radius: 4px 0px 0px 4px;
        }

        .mat-form-field-flex {
          background: #f9f9f9;
        }

        .mat-form-field-outline {
          color: #fff;
          background: #fff;
        }

        .mat-form-field-infix {
          border-top: 5px solid transparent;
          // padding: 0 0 1em 0;
        }

        &.mat-focused {
          .mat-form-field-outline-thick {
            color: var(--primary-color);
          }
        }

        &.mat-form-field-invalid {
          &.mat-form-field-invalid {
            .mat-form-field-outline-thick {
              color: #f44336;
            }
          }
        }
      }

      &.option {
        .mat-form-field-appearance-outline {
          .mat-form-field-flex {
            padding-right: 1px;
          }

          .mat-form-field-infix {
            border-top: 5px solid transparent;
            padding: 0 0 1em 0;
          }

          // .mat-form-field-outline{
          //     color: #fff;
          // }
        }
      }
    }
  }
}

//admission-test page for 991px size

.popup_cont {
  .detail_form {
    .mat-checkbox-label {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }

    .mat-checkbox-checked {
      &.mat-accent {
        .mat-checkbox-background {
          background-color: var(--secondary-color);
        }
      }
    }

    .form_box {
      .mat-form-field-appearance-outline {
        .mat-form-field-outline-end {
          border-radius: 0 4px 4px 0;
        }

        .mat-form-field-outline-start {
          border-radius: 4px 0px 0px 4px;
        }

        .mat-form-field-flex {
          background: #f9f9f9;
        }

        .mat-form-field-outline {
          color: #fff;
          top: 0;
        }

        .mat-form-field-outline-start {
          border: none;
        }

        .mat-form-field-infix {
          border-top: 5px solid transparent;
          // border-top: 0;
          // padding: 0 0 1em 0;
        }

        &.mat-focused {
          .mat-form-field-outline-thick {
            color: var(--primary-color);
          }

          .mat-form-field-outline-start {
            border: 2px solid currentColor;
            border-right: none;
          }
        }

        &.mat-form-field-invalid {
          &.mat-form-field-invalid {
            .mat-form-field-outline-start {
              border: 2px solid currentColor;
              border-right: none;
            }

            .mat-form-field-outline-thick {
              color: #f44336;
            }
          }
        }
      }

      // .mat-form-field-appearance-outline .mat-form-field-outline-start{

      // }
      &.option {
        .mat-form-field-appearance-outline {
          .mat-form-field-flex {
            padding-right: 1px;
          }

          .mat-form-field-infix {
            border-top: 0;
            padding: 0 0 1em 0;
          }

          // .mat-form-field-outline{
          //     color: #fff;
          // }
        }
      }
    }
  }

  .admission_popup_form {
    .mat-button-toggle-checked {
      background-color: var(--primary-color);
      color: #fff;
    }

    .mat-button-toggle-group-appearance-standard {
      border: 0px;
      border-radius: 0px 4px 0px 0px;
      font-size: 12px;

      .mat-button-toggle + .mat-button-toggle {
        border-left: none;

        .mat-button-toggle-label-content {
          border-radius: 0px 4px 0 0;
        }
      }
    }

    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        width: 64px;
        line-height: 25px;
        border: none;
        border-radius: 0px;
        // border-left: #000 solid thin;
        // border-bottom: #000 solid thin;
        border: var(--primary-color) solid thin;
        // border-top: none;
        // border-right: none;
      }
    }
  }
}

.admission_main {
  .info_box {
    .last_step {
      .test_format {
        .mat-radio-label-content {
          font-weight: 400;
          color: #666666;
        }

        .mat-radio-button {
          &.mat-radio-checked {
            .mat-radio-label-content {
              color: #27ae60;
            }
          }
        }

        .mat-radio-button {
          &.mat-accent {
            &.mat-radio-checked {
              .mat-radio-outer-circle {
                border-color: #27ae60;
              }
            }
          }
        }

        .mat-radio-button {
          &.mat-accent {
            .mat-radio-inner-circle {
              background-color: #27ae60;
            }
          }
        }
      }
    }
  }
}

.hint_astrics {
  color: #cd0000;
}

.hint_text {
  font-size: 14px;
  margin-top: 4px;
  opacity: 0.6;
}

// for new dashboard
.owl-carousel {
  padding: 0 15px;

  &.dashboard-carousel {
    width: calc(100% - 60px);
    margin: auto;
    padding: 0;

    .owl-stage {
      display: flex;
      align-items: stretch;
      // .owl-item {
      //     height: 100%;
      // }
    }
  }

  &.advisor-carousel {
    padding: 0;

    .owl-stage {
      display: flex;
      align-items: stretch;
    }

    .owl-dots {
      margin: 30px 0 0 0 !important;

      .owl-dot {
        span {
          margin: 5px 3px;
          background: rgba(var(--primary-color-rgb), 0.2);
          width: 7px;
          height: 7px;
          border-radius: 7px;
        }

        &.active {
          span {
            background: var(--primary-color);
          }
        }
      }
    }
  }
}

//dashboard t-n-c section
.t-n-c-section {
  .mat-checkbox-layout {
    white-space: break-spaces;

    .mat-checkbox-label {
      line-height: normal;
      font-weight: 500;
    }
  }

  .agree {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        font-weight: 700;
      }
    }
  }
}

.show-image {
  .pinch-zoom-content {
    height: 100% !important;
  }
}

.sign-box {
  .canvas_wrapper_div,
  .canvas_whiteboard,
  .incomplete_shapes_canvas_whiteboard {
    height: 190px !important;
  }

  .canvas_wrapper_div {
    border: none !important;
  }

  .canvas_whiteboard {
    border: 2px solid rgba(228, 228, 228, 1);
  }

  .canvas_whiteboard_buttons {
    display: none;
  }
}

@media all and (max-width: 991px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .main_container {
    // padding: 40px 20px 78px;
    padding: 70px 20px 78px;
  }

  .ongoing_apps {
    .mat-expansion-panel-body {
      padding: 0 15px;
    }
  }

  .info_edit_box {
    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        height: 45px;
      }

      .mat-form-field-infix {
        padding: 10px 0;
      }
    }

    .mat-select-value {
      font-size: 12px;
    }
  }

  .main_cont {
    .main_content {
      .mat-form-field-appearance-outline {
        .mat-form-field-outline {
          height: 45px;
        }

        .mat-form-field-infix {
          padding: 10px 0;
        }
      }

      .mat-select-value {
        font-size: 13px;
      }

      .mat-form-field-wrapper {
        padding-bottom: 24px;
      }

      .textareafield {
        .mat-form-field-appearance-outline {
          .mat-form-field-outline {
            height: auto;
          }
        }
      }

      .detail_form {
        .work_exp_box {
          .agreement {
            .mat-radio-label {
              font-size: 15px;
            }
          }

          .mat-checkbox-layout {
            font-size: 14px;
          }
        }
      }

      .cv_upload_box {
        .cv_list {
          .mat-radio-label-content {
            padding-right: 10px;
          }
        }
      }
    }
  }

  .add_experience {
    .mat-form-field-appearance-outline {
      .mat-form-field-infix {
        border-top-width: 10px;
        padding: 8px 0 10px 0;
      }

      .mat-form-field-outline {
        height: 45px;
      }
    }

    .textareafield {
      .mat-form-field-appearance-outline {
        .mat-form-field-outline {
          height: auto;
        }
      }
    }
  }

  // for new dashboard
  .owl-carousel {
    &.dashboard-carousel {
      width: 100%;
      // &.class-carousel {
      //     width: calc(100% - 10px);
      //     float: right;
      // }
      // margin: auto;
      // .owl-stage {
      //     display: flex;
      //     align-items: stretch;
      // }
    }

    &.class-carousel {
      width: calc(100% - 10px);
      margin: 0 0 0 auto;
      // float: right;
      .owl-stage {
        display: flex;
        align-items: stretch;
        // .owl-item {
        //     height: 100%;
        // }
      }
    }
  }
}

//admission-test page css
// .mat-select-panel{
//     margin: 100px 0 0 0;
// }

@media all and (max-width: 479px) {
  .info_edit_box {
    .mat-select-value {
      font-size: 12px;
    }
  }

  .content_main {
    .fullprofile_section,
    .profile_section {
      .mat-button-toggle-group-appearance-standard {
        // .mat-button-toggle+.mat-button-toggle{
        //   border-left: 1px solid #757575;
        // }
        .mat-button-toggle-label-content {
          line-height: 38px !important;
        }
      }

      .mat-select-value {
        font-size: 13px;
      }

      .mat-form-field {
        .mat-form-field-label {
          mat-label {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.dot {
  width: 9px;
  height: 9px;
  background: #d1d6e6;
  border: 1px solid #d1d6e6;
  border-radius: 9px;
}

.line {
  height: 46px;
  width: 1px;
  background: #d1d6e6;
  margin-left: 4px;
  transition: all 1s ease-in-out;
}

.rounded-top {
  border-radius: 24px 24px 0 0 !important;
}

.bottom-sheet-full {
  max-height: 100vh !important;
  padding: 0 !important;
}
.transform-none {
  transform: none !important; //fix position:fixed in bottom sheet
}

.bottom-sheet-85 {
  height: 85vh !important;
  padding: 0 !important;
  margin-top: auto;
}

.self-align-normal {
  align-self: normal;
}

.desktop {
  display: block !important;
}

.mobile {
  display: none !important;
}

@media all and (max-width: 991px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.max-width-90 {
  max-width: 90vw !important;
}

.overflow-scroll {
  overflow-y: scroll;
}

.overlay-dark-bg {
  background: rgba(0, 0, 0, 0.52);
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.72);
}

#fbots-wdgt {
  width: 100% !important;
  height: 100% !important;
  right: 0 !important;
  bottom: 0 !important;
  max-height: none !important;
}
