.scu-text-primary {
  color: var(--color-primary);
}
.scu-text-secondary {
  color: var(--color-secondary);
}
.scu-text-success {
  color: var(--color-success);
}
.scu-text-error {
  color: var(--color-error);
}
.scu-text-info {
  color: var(--color-info);
}
.scu-text-warning {
  color: var(--color-warning);
}
.scu-text-white {
  color: var(--color-white);
}

//background class
.scu-bg-primary {
  background: var(--color-primary);
}
.scu-bg-secondary {
  background: var(--color-secondary);
}
.scu-bg-success {
  background: var(--color-success);
}
.scu-bg-error {
  background: var(--color-error);
}
.scu-bg-info {
  background: var(--color-info);
}
.scu-bg-warning {
  background: var(--color-warning);
}
.scu-bg-white {
  background: var(--color-white);
}
//background light
.scu-bg-primary-light {
  background: var(--color-primary-3);
}
.scu-bg-secondary-light {
  background: var(--color-secondary-3);
}
.scu-bg-success-light {
  background: var(--color-success-3);
}
.scu-bg-error-light {
  background: var(--color-error-3);
}
.scu-bg-info-light {
  background: var(--color-info-3);
}
.scu-bg-warning-light {
  background: var(--color-warning-3);
}
