.wrapper-scu-label{
  display: flex;
  flex-wrap: wrap;
  :not(:first-child){
    margin-left: 20px;
  }
}
.scu-label{
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 12px;
  border-radius: 12px;
  color: var(--comp-info-1);
  background: var(--comp-info-2);
  text-transform: capitalize;
  i{
    margin-right: 4px;
  }
  &.accent-a{
    background: var(--comp-accent-A2);
    color: var(--comp-accent-A);
  }
  &.accent-b{
    background: var(--comp-accent-B2);
    color:  var(--comp-accent-B);
  }
  &.accent-c{
    background: var(--comp-accent-C2);
    color: var(--comp-accent-C);
  }
  &.accent-d{
    background: var(--comp-accent-D2);
    color: var(--comp-accent-D);
  }
  &.warning{
    background: var(--comp-warning-2);
    color: var(--comp-warning-1);
  }
  &.success{
    background: var(--comp-success-2);
    color: var(--comp-success-1);
  }
  &.error{
    background: var(--comp-error-2);
    color: var(--comp-error-1);
  }
  &.small{
    font-size: 12px;
   line-height: 14px;
  }
}
