.scu-toast {
  // layout specification
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 0px 0px 4px 4px;

  // font styles specification
  font-family: 'Public Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  // element size
  max-width: 360px;

  // default toast
  color: var(--color-neutral-1);
  background-color: var(--color-white-6);

  // success toast
  &.success {
    color: var(--comp-success-1);
    background-color: var(--comp-success-2);
  }

  // warning toast
  &.warning {
    color: var(--comp-warning-1);
    background-color: var(--comp-warning-2);
  }

  // error toast
  &.error {
    color: var(--comp-error-1);
    background-color: var(--comp-error-2);
  }

  // toast actions
  .action {
    color: var(--color-neutral-white-7);
    margin-left: auto;
  }
}
.scu-toast-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &.fixed {
    position: fixed;
    bottom: 24px;
    z-index: 999;
  }
  /*
handling position on toast container
by default the toast container is vertically center aligned by
  left: 50%;
  transform: translateX(-50%);
  and text-align: center;
*/
  left: 50%;
  transform: translateX(-50%);
  h4 {
    text-align: center;
  }
  &.right {
    right: 24px;
    left: unset;
    transform: unset;
    h4 {
      text-align: right;
    }
  }
  &.left {
    left: 24px;
    transform: unset;
  }
}
