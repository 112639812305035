@import './styles/normalize.scss';
@import './styles/fonts/public-sans.scss';
@import './styles/variables/colors.scss';
@import './styles/variables/input.scss';
@import './styles/components/input.scss';
@import './styles/variables/button.scss';
@import './styles/variables/menu.scss';
@import './styles/components/button.scss';
@import './styles/components/dialog.scss';
@import './styles/components/tabs.scss';
@import './styles/components/tag.scss';
@import './styles/components/label.scss';
@import './styles/components/toast.scss';
@import './styles/components/scrollbar.scss';
@import './styles/components/menu.scss';
@import './styles/components/multiline-truncate.scss';
@import './styles/components/color-class.scss';

body {
  @include use-public-sans-variable();
}

html[data-prefers-dark-theme='true'] {
  body {
    background: hsla(223, 51%, 27%, 1);
  }
}
