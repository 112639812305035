@import '~src/assets/css/mixins';
/**
    Adds fixed button row at the bottom
*/
.bottom-fixed-button-container {
  display: flex;
  visibility: visible;
  transition: opacity 200ms ease-in;
  -webkit-user-select: none;
  user-select: none;
  background: white;
  padding: 12px 16px;
  position: fixed;
  bottom: var(--bottom-navigation-height);
  left: 0;
  width: 100%;
  margin-top: auto;
  z-index: 10;

  & > * {
    flex: 2;
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
  }
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.margin-spacer {
  height: 32px;
}

.margin-spacer-sm {
  height: 24px;
}
