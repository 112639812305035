.no-scrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin no-scrollbar() {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
