@import '~src/assets/css/mixins';

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-grey {
  color: rgba(34, 54, 106, 0.55) !important;
}

.text-link {
  color: var(--blazing-blue);
}

.text-link-dark {
  color: var(--text-light-blue);
}

.extra-bold {
  font-weight: 900 !important;
}

.bold {
  font-weight: bold !important;
}

.normal {
  font-weight: 400 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-gap {
  gap: 1rem;
}

.flex-gap-lg {
  gap: 2rem;
}

.space-between {
  justify-content: space-between;
}

.flex-grow {
  flex: 1;
}

.flex-gap-sm {
  gap: 0.6rem;
}

.no-margin {
  margin: 0 !important;
}

.pointer {
  cursor: pointer;
}

.highlight-red {
  color: #f00;
}

.m-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.adaptive-button-container {
  background: #fff;
  padding: 8px 20px;
  filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.12));
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: auto;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 1;

  &-visible {
    visibility: visible;
    opacity: 1;
  }

  // @include respond-to('medium') {
  //   padding: 2rem 26%;
  // }

  &__full-width {
    @include respond-to('medium') {
      padding: 2rem;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.adaptive-button {
  background: var(--primary-color);
  border-radius: 8px;
  padding: 1.2rem;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: white;
  transition: background 0.2s ease-in-out;
  cursor: pointer;

  @include respond-to('medium') {
    padding: 1.6rem;
  }

  &-disabled {
    background: rgba(34, 54, 106, 0.2);
  }
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 18px;
  height: 18px;
  border-bottom: 1px solid var(--primary-color);
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid transparent;
  border-radius: 100%;
  animation: rotateSpin 0.5s infinite linear;
}

@keyframes rotateSpin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

.global-otp-input {
  width: 35px !important;
  height: 35px !important;
  font-size: 14px !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--border-light-blue) !important;
  border-radius: 4px !important;
}

.gap-sm {
  gap: 1rem;
}

.gap-xsm {
  gap: 0.4rem;
}

.readonly-wrapper {
  cursor: not-allowed;

  .readonly-block {
    pointer-events: none;
  }
}

.full-height {
  height: 100%;
}

.no-border-radius {
  border-radius: 0;
}

.no-anim {
  animation: none;
}

.warning-box {
  background: var(--depressing-yellow-light);
  border-radius: 4px;
  padding: 1rem;
}

.mt-auto {
  margin-top: auto;
}
.flex-end {
  align-self: flex-end;
}

.flex-start {
  align-self: flex-start;
}

.full-width-vw {
  max-width: 100vw !important;
}

.full-width {
  width: 100%;
}

.uploader-container {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
}

.uploader-msg {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
  margin: 0 0 4px;
}
