@mixin select-inputs {
  & > input[type='text'],
  & > input[type='number'],
  & > input[type='tel'],
  & > input[type='email'],
  & > input[type='time'],
  & > select,
  & > textarea {
    @content;
  }
}

@mixin normalize-input {
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
  outline: none;
  background: transparent;
}

.scu-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  @include select-inputs() {
    height: 36px;
    @include normalize-input();

    padding: var(--input-default-padding-top) var(--input-default-padding-right) var(--input-default-padding-bottom)
      var(--input-default-padding-left);

    border: 1px solid var(--input-default-border-color);
    border-radius: var(--input-default-border-radius);

    font-size: var(--input-default-font-size);
    color: var(--input-default-color);
    line-height: var(--input-default-line-height);
    font-weight: var(--input-default-font-weight);

    &::placeholder {
      color: var(--input-default-placeholder-color);
      font-size: var(--input-default-font-size);
      line-height: var(--input-default-line-height);
    }

    &:focus {
      border-color: var(--input-focus-border-color);
      & ~ .scu-input-helper-text {
        color: var(--color-primary);
      }
      & ~ scu-icon {
        color: var(--color-primary);
      }
    }
  }

  & > textarea {
    min-height: var(--text-area-min-height);
  }

  & scu-icon {
    color: var(--input-start-icon-color);
    font-size: 16px;
    position: absolute;
    top: var(--input-icon-top);
    right: var(--input-end-icon-right);
  }

  & > .scu-input-label {
    margin-bottom: var(--input-label-margin-bottom);
    // Change icon vertical offset if label available
    & ~ scu-icon {
      top: calc(var(--input-icon-top) + 26px);
    }
  }
  & > .scu-input-helper-text {
    margin-top: var(--input-helper-text-margin-top);
  }

  .block {
    max-width: unset;
    width: 100%;
  }
}

.scu-input-container.error {
  @include select-inputs() {
    border-color: var(--color-error);
    color: var(--color-error);
  }

  & > .scu-input-helper-text {
    color: var(--color-error);
  }

  & scu-icon {
    color: var(--color-error);
  }
}

.scu-input-container.success {
  @include select-inputs() {
    border-color: var(--color-success);
    color: var(--color-success);
  }

  & > .scu-input-helper-text {
    color: var(--color-success);
  }

  & scu-icon {
    color: var(--color-error);
  }
}

.scu-input-container.warning {
  @include select-inputs() {
    border-color: var(--color-warning);
    color: var(--color-warning);
  }

  & > .scu-input-helper-text {
    color: var(--color-warning);
  }

  & scu-icon {
    color: var(--color-error);
  }
}

.scu-input-label {
  display: block;
  font-size: var(--input-label-font-size);
  line-height: var(--input-label-line-height);
  color: var(--input-label-color);
}

.scu-input-helper-text {
  font-size: var(--input-helper-text-font-size);
  line-height: var(--input-helper-text-line-height);
  color: var(--input-helper-text-color);
}
