.wrapper-scu-tag {
  display: flex;
  flex-wrap: wrap;
  :not(:first-child) {
    margin-left: 20px;
  }
}
.scu-tag {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 12px;
  border-radius: 50px;
  color: var(--comp-info-2);
  background: var(--comp-info-1);
  text-transform: capitalize;
  i {
    margin-right: 4px;
    font-size: 12px !important;
  }
  &.accent-a {
    background: var(--comp-accent-A4);
    color: var(--comp-accent-A2);
  }
  &.accent-b {
    background: var(--comp-accent-B);
    color: var(--comp-accent-B2);
  }
  &.accent-c {
    background: var(--comp-accent-C);
    color: var(--comp-accent-C2);
  }
  &.accent-d {
    background: var(--comp-accent-D);
    color: var(--comp-accent-D2);
  }
  &.warning {
    background: var(--comp-warning-1);
    color: var(--comp-warning-2);
  }
  &.success {
    background: var(--comp-success-1);
    color: var(--comp-success-2);
  }
  &.error {
    background: var(--comp-error-1);
    color: var(--comp-error-2);
  }
  &.small {
    font-size: 12px;
    line-height: 14px;
    padding: 2px 8px;
  }
}
